import React, { useMemo } from 'react';
import {
  AggregatedProductModule,
  ProductModuleType,
} from '../../AggregatedProductModule';
import { ProductsGrid } from '../../../productFilter/products';
import { ArticleCard } from '../../../productFilter/cards';
import { getDiscoveryContentByType, isFilterEnabled } from '../../../helpers';
import {
  DiscoveryFeaturedTypes,
  DiscoveryArticle,
} from '../../../../../../store/features/discovery';
import {
  DiscoveryContentTypes,
  HubProductFilterPosition,
} from '../../../../../../constants';
import { PropsWithTestId } from '../../../../../../types';
import { useHubContext } from '../../../context/hubContext';
import { useRootSelector } from '../../../../../../store/hooks';
import { selectAudienceHubPrimaryTopic } from '../../../../audienceHub/store/features/options';

export enum ArticlesModuleDisplayTypes {
  'INFORMACONNECT' = 'INFORMACONNECT',
  'FAST_FORUMS' = 'FAST_FORUMS',
}

export enum ArticlesAmountOptions {
  FOUR = 1,
  EIGHT = 2,
  TWELVE = 3,
}

export interface ArticlesModuleType extends ProductModuleType {
  displayStyle: ArticlesModuleDisplayTypes;
  amountFeaturedArticles: keyof typeof ArticlesAmountOptions;
}

export interface ArticlesModuleProps {
  module: ArticlesModuleType;
}

export default function ArticlesModule(
  props: PropsWithTestId<ArticlesModuleProps>,
) {
  const { module, testId } = props;
  const { brandName } = useHubContext();

  const {
    amountFeaturedArticles,
    displayStyle,
    discovery: { records = [] } = {},
    searchQueryData,
    filterPosition,
  } = module;
  const FILTER_LEFT = filterPosition === HubProductFilterPosition.LEFT;
  const FILTER_TOP = filterPosition === HubProductFilterPosition.TOP;
  const filterEnabled = isFilterEnabled(searchQueryData.facetsConfig);
  const showLeftFilter = filterEnabled && FILTER_LEFT;
  const showTopFilter = filterEnabled && FILTER_TOP;
  const subBrands = useMemo(() => {
    const brands = searchQueryData.subBrands || [];
    return brands.length > 0 ? brands : [brandName];
  }, [brandName, searchQueryData.subBrands]);
  const mainFilterTopic = useRootSelector(selectAudienceHubPrimaryTopic);

  const defaultValues = useMemo(
    () => ({
      subBrands,
      availableSubBrands: subBrands,
      sectors: [],
      topics: [],
      mainFilterTopic,
    }),
    [mainFilterTopic, subBrands],
  );

  const { results = [], count: resultsCount = 0 } =
    getDiscoveryContentByType<DiscoveryArticle>(
      DiscoveryContentTypes.ARTICLE,
      records,
    );

  const products = useMemo(() => {
    return results.filter(
      (item) => item.featuredType !== DiscoveryFeaturedTypes.LARGE,
    );
  }, [results]);

  const featuredProducts = useMemo(() => {
    return results.filter(
      (item) => item.featuredType === DiscoveryFeaturedTypes.LARGE,
    );
  }, [results]);

  const inlineProductsCount = 6;
  const inlineProductsSlotsCount = 2;
  const rowsCount = ArticlesAmountOptions[amountFeaturedArticles];
  const countPerRow = showLeftFilter ? 3 : 4;
  const amountOfFeaturedArticles =
    rowsCount * countPerRow - inlineProductsSlotsCount;

  return (
    <AggregatedProductModule
      module={module}
      moduleName="articles"
      brandName={brandName}
      defaultValues={defaultValues}
      resultsCount={resultsCount}
      showLeftFilter={showLeftFilter}
      showTopFilter={showTopFilter}
      showMoreButtonURL="/articles"
      bottomShowMoreButtonLabel="Search All News & Insights"
      topShowMoreButtonLabel="Browse All News & Insights"
      testId={testId}
    >
      {displayStyle === ArticlesModuleDisplayTypes.FAST_FORUMS && (
        <ProductsGrid<DiscoveryArticle>
          products={products.slice(0, rowsCount * countPerRow)}
          featuredProducts={featuredProducts}
          renderProduct={(product) => <ArticleCard data={product} />}
          renderFeaturedProduct={(product) => (
            <ArticleCard
              data={product}
              cardType="featured"
              showFilter={filterEnabled}
            />
          )}
          productsCountPerRow={showLeftFilter ? 'three' : 'four'}
        />
      )}

      {displayStyle === ArticlesModuleDisplayTypes.INFORMACONNECT && (
        <ProductsGrid<DiscoveryArticle>
          featuredProducts={featuredProducts}
          products={products.slice(0, amountOfFeaturedArticles)}
          inlineProducts={products.slice(
            amountOfFeaturedArticles,
            amountOfFeaturedArticles + inlineProductsCount,
          )}
          renderProduct={(product) => <ArticleCard data={product} />}
          renderInlineProduct={(product) => (
            <ArticleCard data={product} cardType="inline" />
          )}
          renderFeaturedProduct={(product) => (
            <ArticleCard
              data={product}
              cardType="featured"
              showFilter={filterEnabled}
            />
          )}
          productsCountPerRow={showLeftFilter ? 'three' : 'four'}
        />
      )}
    </AggregatedProductModule>
  );
}

ArticlesModule.defaultProps = {
  testId: 'hub-articles-module',
};
